import { Box, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import BusinessUnitAPI from "../api/BusinessUnit.api";
import { BusinessUnitInput } from "../types";
import ArchiveButton from "./ArchiveButton";

type Props = {
  businessUnit: BusinessUnitInput;
};
export default function BusinessUnitForm({ businessUnit }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = React.useState<string>(businessUnit.name);
  const [fdaNumber, setFdaNumber] = React.useState<string>(
    businessUnit.fdaNumber,
  );
  const [labelerCode, setLabelerCode] = React.useState<string>(
    businessUnit.labelerCode,
  );
  const [dea, setDea] = React.useState<string>(businessUnit.dea);
  const [hin, setHin] = React.useState<string>(businessUnit.hin);
  const [ediSystemApiKey, setEdiSystemApiKey] = React.useState<string>(
    businessUnit.ediSystemApiKey,
  );

  const { mutateAsync } = BusinessUnitAPI.useSave({
    ...businessUnit,
    name,
    fdaNumber,
    labelerCode,
    dea,
    hin,
    ediSystemApiKey,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
        sx={{ marginBottom: "1rem" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="FDA Number"
          variant="outlined"
          value={fdaNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFdaNumber(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Labeler Code"
          variant="outlined"
          value={labelerCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLabelerCode(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="DEA"
          variant="outlined"
          value={dea}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDea(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="HIN"
          variant="outlined"
          value={hin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHin(event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextField
          label="EDI System API key"
          variant="outlined"
          value={ediSystemApiKey}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEdiSystemApiKey(event.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button variant="contained" color="primary" onClick={throttledOnSave}>
          Save
        </Button>
        {businessUnit.id && <ArchiveBusinessUnitButton id={businessUnit.id} />}
      </Box>
    </Box>
  );
}

type ArchiveBusinessUnitButtonProps = {
  id: number;
};

function ArchiveBusinessUnitButton({ id }: ArchiveBusinessUnitButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = BusinessUnitAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return <ArchiveButton onDelete={throttledHandleDelete} />;
}
